import React, {forwardRef, useContext, useEffect} from "react";
import { TerminalContext } from "../terminal/terminal";
import { TerminalLineI } from "../terminal/terminal";
import useAxiosAuthClient from "src/network/interceptors";

const FileInput = forwardRef<HTMLInputElement, React.InputHTMLAttributes<HTMLInputElement>>(
    (props, ref) => {

        const terminalContext = useContext(TerminalContext);
        const AxiosAuthClient = useAxiosAuthClient();

        const addFilesToContainer = (fileNames: string[]) => {
          terminalContext?.setLines((prevLines: TerminalLineI[]) => {
              const lastLineIndex = prevLines.length - 1;
              const lastLine = { ...prevLines[lastLineIndex] };
              const lastContainerIndex = lastLine.input.containers.length - 1;
              const lastContainer = { ...lastLine.input.containers[lastContainerIndex] };
              const updatedContainer = {
                  ...lastContainer,
                  files: [...lastContainer.files, ...fileNames],
              };
              const updatedLine = {
                  ...lastLine,
                  input: {
                      ...lastLine.input,
                      containers: [
                          ...lastLine.input.containers.slice(0, lastContainerIndex),
                          updatedContainer,
                          ...lastLine.input.containers.slice(lastContainerIndex + 1),
                      ],
                  },
              };
              return [
                  ...prevLines.slice(0, lastLineIndex),
                  updatedLine,
              ];
          });
      };
        
        const handleFileUpload = async (files: FileList | null) => {
            if (!files) return;
            const uniqueFiles = Array.from(files).map((file, index) => {
              const uniqueId = `${Date.now()}${index}`;
              const uniqueFileName = `${uniqueId}_${file.name}`;
              return new File([file], uniqueFileName, { type: file.type });
            });

            const formData = new FormData();
            uniqueFiles.forEach((file) => {
              formData.append('files', file);
            });
      
            try {
              terminalContext?.setUploadComplete("loading");
              terminalContext?.updateContainerStatus(terminalContext.lines.length - 1, terminalContext.lines[terminalContext.lines.length - 1].input.containers.length - 1, "loading")
              const response = await AxiosAuthClient.post('/uploads/uploadFiles', formData)
              console.log("response.data" ,response.data)
              if (response.status === 200) {
                const uploads  = response.data.filenames;
                terminalContext?.updateContainerStatus(terminalContext.lines.length - 1, terminalContext.lines[terminalContext.lines.length - 1].input.containers.length - 1, "success")
                terminalContext?.setUploadComplete("success");
                terminalContext?.appendInput()
              } else {
                console.error('Error uploading files');
                terminalContext?.updateContainerStatus(terminalContext.lines.length - 1, terminalContext.lines[terminalContext.lines.length - 1].input.containers.length - 1, "error")
                terminalContext?.setUploadComplete("error");
              }
            } catch (error) {
              console.error('Error:', error);
              terminalContext?.setUploadComplete("error");
            }
          };
          
          useEffect(() => {
            if (terminalContext?.uploadFiles) {
              const filenames: string[] = Array.from(terminalContext.uploadFiles).map((file) => file.name);
              addFilesToContainer(filenames)
              handleFileUpload(terminalContext.uploadFiles);
            }
          }, [terminalContext?.uploadFiles]);

        return (
            <input
                ref={ref}
                type="file"
                id="fileInput"
                accept=".pdf"
                style={{ display: 'none' }}
                multiple={true}
                {...props}
            />
        );
    }
);

export default FileInput;