import React, {act, createContext, ReactNode, useContext, useRef, useState} from "react";
import styles from './control_panel.module.css'
import buttonStyles from '../buttons/buttons.module.css'
import { BsBookmark, BsTools, BsListCheck, BsRobot, BsTable, BsDatabase} from 'react-icons/bs'
import { PiTreeView, PiGraphDuotone } from "react-icons/pi";
import { FaRegFileAlt  } from "react-icons/fa";
import BaseButton from "../buttons/buttons";
import { AgentPanel } from "./agent/agent";
import GraphView from "./graph_explorer/graph";
import { DocumentPanel } from "./documents/document_panel";
import Table from "./spreadsheet/spreadsheets";

const ControlPanelContext = createContext({
    activeTab:0,
    setActiveTab:(tab:number)=>{}
});

export const useControlPanelContext = ()=>useContext(ControlPanelContext);

interface tabProps{
    isActive: boolean;
    onClick: ()=>void;
    tabName: string;
    children : React.ReactNode;
}

export function ControlTabContent({children, tabName}:{children : React.ReactNode, tabName:string})
{
    return (
        <div className={`${styles.control_tab_content}`}>
            {children}
        </div>
    )
}

export function ControlTab({ isActive, onClick, tabName, children} : tabProps)
{
    return (
        <div>
            <BaseButton Label={null} onClick={onClick} className={isActive ? styles.active_tab_button : ""}>
                {children}
            </BaseButton>
        </div>

    )
}

export function ControlPanelNav()
{
    const { activeTab, setActiveTab } = useControlPanelContext();

    return (
        <div id={styles.control_panel_nav}>
            <ControlTab isActive={activeTab === 0} onClick={()=>setActiveTab(0)} tabName="Agents">
                <PiTreeView  className={buttonStyles.button_icon}></PiTreeView>
            </ControlTab>
            <ControlTab isActive={activeTab === 3} onClick={()=>setActiveTab(3)} tabName="Tasks">
                <FaRegFileAlt  className={buttonStyles.button_icon}></FaRegFileAlt >
            </ControlTab>
            <ControlTab isActive={activeTab === 1} onClick={()=>setActiveTab(1)} tabName="SpreadSheets">
                <PiGraphDuotone  className={buttonStyles.button_icon}></PiGraphDuotone >
            </ControlTab>
            {/* <ControlTab isActive={activeTab === 2} onClick={()=>setActiveTab(2)} tabName="Database">
                <BsDatabase className={buttonStyles.button_icon}></BsDatabase>
            </ControlTab> */}
        </div>
    )
}

export default function ControlPanel()
{
    const [activeTab, setActiveTab] = useState(0);
    const tabNames = ["Agents", "Database", "Tasks", "Tools"];

    const showActiveTabContent = ()=>{
        switch (activeTab)
        {
            case 0:
                return (
                    <ControlTabContent tabName={tabNames[activeTab]}>
                        <AgentPanel />
                    </ControlTabContent>
                )
            case 1:
                return (
                    <ControlTabContent tabName={tabNames[activeTab]}>
                        <GraphView></GraphView>
                    </ControlTabContent>
                )
            case 2:
                return (
                    <ControlTabContent tabName={tabNames[activeTab]}>
                        <div></div>
                    </ControlTabContent>
                )
            case 3:
                return (
                    <ControlTabContent tabName={tabNames[activeTab]}>
                        <DocumentPanel/>
                    </ControlTabContent>
                )
            case 4:
                return (
                    <ControlTabContent tabName={tabNames[activeTab]}>
                        <div></div>
                    </ControlTabContent>
                )
        }
    }

    return (
        <ControlPanelContext.Provider value={{ activeTab, setActiveTab }}>
            <div id={styles.control_panel}>
                <ControlPanelNav />
                {showActiveTabContent()}
            </div>
        </ControlPanelContext.Provider>
    )
}