import React, {ChangeEvent, FormEvent, useState, useContext, useEffect} from "react";
import styles from './csv_agent.module.css'
import useAxiosAuthClient from "src/network/interceptors";
import CustomFileInput from "src/components/forms/custom_file_input";
import { Upload, UploadFile } from "@mui/icons-material";
import { FaUpload } from "react-icons/fa";
import { TerminalContext } from "../../terminal";

export function CsvAgentCommand({ lineIndex, containerIndex }: { lineIndex: number; containerIndex: number }) {
    const [csvFile, setCsvFile] = useState<File | null>(null);
    const [csvAgentQuery, setCsvAgentQuery] = useState("");
    const terminalContext = useContext(TerminalContext);
    const AxiosAuthClient = useAxiosAuthClient();

    useEffect(() => {
        if (csvFile || csvAgentQuery) {
            terminalContext?.updateContainerCommand(lineIndex, containerIndex, {
                type: "agent",
                description:"CSV",
                data: { csvFile, csvAgentQuery }
            });
        }
    }, [csvAgentQuery, csvFile, lineIndex, containerIndex]);

    const runQuery = async (event: React.FormEvent) => {
        event.preventDefault();

        const formData = new FormData();
        if (csvFile) formData.append("csvFile", csvFile);
        formData.append("csvAgent_query", csvAgentQuery);

        try {
            const response = await AxiosAuthClient.post("/agents/csvAgent", formData, {
                headers: { "Content-Type": "multipart/form-data" },
            });
            console.log("Response: ", response.data);
        } catch (error) {
            console.error("Error: ", error);
        }
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.currentTarget.files && e.currentTarget.files.length > 0) {
            setCsvFile(e.currentTarget.files[0]);
        } else {
            setCsvFile(null);
        }
    };

    return (
        <form onSubmit={runQuery} className={styles.agent_command}>
            <div style={{ display: "flex", gap: "10px" }}>CSV Query</div>
            <div style={{ display: "flex", gap: "10px" }}>
                <CustomFileInput
                    icon={<FaUpload className="button_icon" />}
                    accept=".csv"
                    type="file"
                    name="csvFile"
                    onChange={handleChange}
                    multiple={false}
                />
                {csvFile ? <span>{csvFile.name}</span> : null}
            </div>
            <div className={styles.agent_prompt} style={{ display: "flex", flexDirection: "column", alignItems: "center", boxSizing: "border-box" }}>
                <textarea
                    name="csvAgent_query"
                    value={csvAgentQuery}
                    onChange={(e) => setCsvAgentQuery(e.target.value)}
                    placeholder={"query the file for data"}
                />
            </div>
            <button type="submit">Run</button>
        </form>
    );
}