import React, {act, createContext, ReactNode, useContext, useRef, useState, useEffect} from "react";
import styles from './agent.module.css'
import AgentsWebSocketProvider, { useAgentsWebSocket } from "src/websockets/agents_ws";
import Spinner from "../../spinners/beatloader";
import Markdown from "react-markdown";
import remarkGfm from 'remark-gfm'
import { TextArea } from "../../terminal/terminal";

interface AgentsPanelContextI
{
    selectedAgentInstance : string,
    setSelectedAgentInstance : (selectAgentInstance : string)=>void,
    agentId : string,
    setAgentId : (agentId : string)=>void,
    filterPrompt : string | null,
    setFilterPrompt:(filterPrompt:string|null)=>void,
}

const AgentPanelContext = createContext<AgentsPanelContextI>({
    selectedAgentInstance: "",
    setSelectedAgentInstance: () => {},
    agentId: "",
    setAgentId: () => {},
    filterPrompt: null,
    setFilterPrompt: ()=>{}
});

export function AgentPanel(){
    const [agentId, setAgentId] = useState<string>("");
    const [selectedAgentInstance, setSelectedAgentInstance] = useState<string>("");
    const [filterPrompt, setFilterPrompt] = useState<string|null>(null);

    return (
        <AgentsWebSocketProvider>
            <AgentPanelContext.Provider value={
                { 
                    filterPrompt,
                    setFilterPrompt,
                    selectedAgentInstance, 
                    setSelectedAgentInstance, 
                    agentId, 
                    setAgentId
                }
            }>
                <div className={styles.Agent}>
                    <SearchPrompt/>
                    <AgentList />
                    <AgentProcessDisplay/>
                </div>
            </AgentPanelContext.Provider>
        </AgentsWebSocketProvider>
    )
}

interface Instance {
    output: string;
    createdAt:string;
}

interface AgentData {
    instances: Record<string, Instance>;
}

interface Agent {
    agentType: string;
    instances: Record<string, Instance>;
}

export function AgentList() {
    const [agentList, setAgentList] = useState<Agent[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const [expandedAgents, setExpandedAgents] = useState<Set<string>>(new Set());
    const AgentsContext = useContext(AgentPanelContext);
    const AgentsWsContext = useAgentsWebSocket();
    const { filterPrompt } = AgentsContext;

    useEffect(() => {
        const agentsPayload = AgentsWsContext?.AgentsPayload;

        if (agentsPayload) {
            const agentsArray: Agent[] = Object.entries(agentsPayload.agents).map(([agentId, agentData]) => {
                const typedAgentData = agentData as AgentData;
                return {
                    agentType: agentId,
                    instances: typedAgentData.instances,
                };
            });
            setAgentList(agentsArray);
            setLoading(false);
        } else {
            setLoading(false);
        }
    }, [AgentsWsContext?.AgentsPayload]);

    const toggleExpand = (agentType: string) => {
        setExpandedAgents((prev) => {
            const newExpanded = new Set(prev);
            if (newExpanded.has(agentType)) {
                newExpanded.delete(agentType);
            } else {
                newExpanded.add(agentType);
            }
            return newExpanded;
        });
    };

    const selectAgentInstance = (agentType: string, instanceKey: string) => {
        console.log(agentType, instanceKey);
        AgentsContext.setSelectedAgentInstance(instanceKey);
        AgentsContext.setAgentId(agentType);
    };

    const filteredAgentList = agentList.filter(agent =>
        (agent.agentType.toLowerCase().includes((filterPrompt ?? "").toLowerCase()))
    );
    
    return (
        <div className={styles.agent_list}>
            {loading && <Spinner />}
            {error && <p>{error}</p>}
            {filteredAgentList.length === 0 && !loading}
            <table className={styles.agents_list_table}>
                <tbody className={styles.agent_list_table_body}>
                    {filteredAgentList.slice().reverse().map((agent, index) => (
                        <React.Fragment key={index}>
                            <tr>
                                <td>
                                    <button
                                        onClick={() => {toggleExpand(agent.agentType);AgentsContext.setAgentId(agent.agentType)}}
                                        className={`${AgentsContext.agentId === agent.agentType ? styles.selectedAgent : ""}`}
                                        style={{ cursor: 'pointer'}}>
                                        {agent.agentType}
                                    </button>
                                </td>
                            </tr>
                            {expandedAgents.has(agent.agentType) && (
                                <tr className={styles.agent_instance_row}>
                                    <td className={styles.agent_instance_data} colSpan={2}>
                                        <ul>
                                            {Object.entries(agent.instances).map(([instanceKey, instance]) => (
                                                <div className={styles.agent_entry} style={{display:"flex", flexDirection:"row", gap:"10px"}}>
                                                    <button
                                                        key={instanceKey}
                                                        className={`${styles.agent_instance_button} ${AgentsContext.selectedAgentInstance === instanceKey ? styles.selectedInstance : ""}`}
                                                        onClick={() => selectAgentInstance(agent.agentType, instanceKey)}
                                                    >
                                                        {instanceKey}
                                                    </button>
                                                    <span className={styles.instance_date}>
                                                        {new Date(instance.createdAt).toLocaleString()}
                                                    </span>
                                                </div>
                                            ))}
                                        </ul>
                                    </td>
                                </tr>
                            )}
                        </React.Fragment>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export function SearchPrompt() {
    const context = useContext(AgentPanelContext);

    const searchValue = (e: React.ChangeEvent<HTMLInputElement>) => {
        context.setFilterPrompt(e.currentTarget.value);
    };
    return (
        <div className={styles.promptFilter}>
            <input className={styles.promptFilterSearch} type="text" onInput={searchValue} />
        </div>
    );
}

export function AgentControl(){
    return (
        <div className={styles.agent_control}>
            <div className={styles.agent_stats}>
                <button><div className={styles.stats_active}></div></button>
                <button><div className={styles.stats_active}></div></button>
                <button><div className={styles.stats_active}></div></button>
                <button><div className={styles.stats_active}></div></button>
            </div>
        </div>
    )
}


export function AgentProcessDisplay() {
    const [output, setOutput] = useState<string | null>(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const AgentsContext = useContext(AgentPanelContext);
    const AgentsWsContext = useAgentsWebSocket();

    useEffect(() => {
        const parsed_data = AgentsWsContext?.AgentsPayload;
        if (parsed_data) {
            const agentId = AgentsContext.agentId;
            const instanceId = AgentsContext.selectedAgentInstance;
            if (parsed_data.agents[agentId] && parsed_data.agents[agentId].instances[instanceId]) {
                const selectedOutput = parsed_data.agents[agentId].instances[instanceId].output;
                setOutput(selectedOutput);
            } else {
                setOutput(null);
            }
        }
    }, [AgentsWsContext?.AgentsPayload, AgentsContext.agentId, AgentsContext.selectedAgentInstance]);

    const isHTML = (str: string) => /<\/?[a-z][\s\S]*>/i.test(str);

    return (
        <div className={styles.agent_process_display}>
            {loading ? (
                <Spinner />
            ) : output !== null ? (
                isHTML(output) ? (
                    <div
                        className={styles.agent_process_display_html}
                        dangerouslySetInnerHTML={{ __html: output }}
                    />
                ) : (
                    <Markdown className={`md_content ${styles.agent_process_display_md}`} remarkPlugins={[remarkGfm]}>
                        {JSON.parse(output)}
                    </Markdown>
                )
            ) : null}
        </div>
    );
}

export function AgentOutput()
{
    return (
        <div className={styles.agent_process_step}>
            Step1
        </div>
    )
}