import React, {createContext, useContext, useEffect, useState} from 'react';
import styles from './chat_app.module.css'
import Terminal from '../../components/terminal/terminal'
import ChatNavbar from '../../components/navbars/chat_navbar'
import UserMenu from '../../components/menus/user_menu';
import ControlPanel from '../../components/control_panel/control_panel'
import './chat_app.module.css'
import App from '../../App';
import { useAuth0 } from '@auth0/auth0-react';
import { TheaterComedy } from '@mui/icons-material';

import { useNavigate } from 'react-router-dom';

import AuthPage from '../auth/authenticate';

interface AppContextInt{
    accessToken : string | null,
    theme : string,
    setTheme : React.Dispatch<React.SetStateAction<string>>;
    showMenu : boolean;
    setShowMenu : (showMenu : boolean)=>void;
}

export const AppContext = createContext<AppContextInt | undefined>(undefined)

export default function ChatApp()
{
    const [theme, setTheme] = useState("dark");
    const [showMenu, setShowMenu] = useState(false);
    const { getAccessTokenSilently, isAuthenticated } = useAuth0();
    const [accessToken, setAccessToken] = useState<string | null>(null);

    useEffect(() => {
        const fetchToken = async () => {
            if (isAuthenticated) {
                try {
                    const token = await getAccessTokenSilently();
                    setAccessToken(token);
                } catch (error) {
                    console.error("Error fetching access token:", error);
                }
            }
        };

        fetchToken();
    }, [isAuthenticated, getAccessTokenSilently]);

    return (
        <AppContext.Provider value={{accessToken, theme, setTheme, showMenu, setShowMenu}}>
            <div id={styles.main_container} data-theme={theme}>
                <ChatNavbar></ChatNavbar>
                <Terminal></Terminal>
                <ControlPanel></ControlPanel>
                {showMenu ? <UserMenu/> : null}
            </div>
        </AppContext.Provider>
    )    
}