import { AppContext } from '../../pages/chat/chat_app'
import styles from './chat_navbar.module.css'
import React, { useState, useContext, createContext } from 'react'
import BaseButton , { ToggleSideBarButton, SettingsButton, AccountButton, ToggleLightModeButton } from '../buttons/buttons'
import { Home } from '@mui/icons-material'

const NavBarContext = createContext({});

export function NavbarButtons({children}:{children:React.ReactNode})
{ 
    return (
        <div className={styles.navbar_buttons}>
            {children}
        </div>
    )           
}

export default function ChatNavbar()
{
    const [showSettings, setShowSettings] = useState(false);
    const appContext = useContext(AppContext);


    return (
        <NavBarContext.Provider value={{showSettings, setShowSettings}}>
            <nav id={styles.navbar} className='navbar'>
                <NavbarButtons>
                    <ToggleLightModeButton onClick={() => appContext?.setTheme(appContext?.theme === 'light' ? 'dark' : 'light')}></ToggleLightModeButton>
                    <SettingsButton></SettingsButton>
                    <AccountButton onClick={()=>{appContext?.setShowMenu(appContext.showMenu === true ? false : true)}}></AccountButton>
                </NavbarButtons>
            </nav>
        </NavBarContext.Provider>
    )
}