import React, { useContext, useEffect } from "react";
import { EmbeddedMenu } from "../menus/embedded";
import { TerminalInputContainer } from "../terminal/terminal";
import { TerminalContext } from "../terminal/terminal";
import BeatLoader from "../spinners/beatloader";
import { FaCheckCircle, FaCross } from "react-icons/fa";
import styles from './uploaded_files.module.css'
import { upload } from "@testing-library/user-event/dist/upload";
import { MdOutlineErrorOutline } from "react-icons/md";
import { LineStyle } from "@mui/icons-material";


export function PromptFileUploadList({ lineIndex, containerIndex }: { lineIndex: number; containerIndex: number }) {
    const terminalContext = useContext(TerminalContext);
    const uploadedFiles = terminalContext?.lines[lineIndex].input.containers[containerIndex].files;
    const uploadStatus = terminalContext?.lines[lineIndex].input.containers[containerIndex].status;

    const isCurrent = terminalContext?.lines.some(
        (line, lIndex) =>
            lIndex === lineIndex &&
            line.input.containers.some(
                (container, cIndex) =>
                    cIndex === containerIndex && container.status === "loading"
            )
    );

    return (
        <>
            {isCurrent && uploadedFiles?.length === 0 ? (
                <BeatLoader />
            ) : (
                <EmbeddedMenu>
                    <div>
                        {uploadStatus === "success" ? (
                            <FaCheckCircle />
                        ) : uploadStatus === "loading" ? (
                            <BeatLoader />
                        ) : uploadStatus === "error" ? (
                            <MdOutlineErrorOutline />
                        ) : null}
                    </div>
                    {uploadedFiles?.map((file, index) => (
                        <div key={index} className={styles.file_item}>
                            <i className="file-icon"></i>
                            <span>{file}</span>
                        </div>
                    ))}
                </EmbeddedMenu>
            )}
        </>
    );
}


export default function InPromptFile(file_extension : string)
{
    return (
        <i className={`bi ${file_extension} file-icon`}></i>
    )
}