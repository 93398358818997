import React from 'react';
import styles from './beatloader.module.css';

const BeatLoader = () => {
    return (
        <div className={styles.beatLoader}>
            <div className={styles.beat}></div>
            <div className={styles.beat}></div>
            <div className={styles.beat}></div>
        </div>
    );
};

export default BeatLoader;
