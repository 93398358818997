import React, {ChangeEvent, FormEvent, useState, useContext, useEffect} from "react";
import styles from './csv_agent.module.css'
import useAxiosAuthClient from "src/network/interceptors";
import CustomFileInput from "src/components/forms/custom_file_input";
import { Upload, UploadFile } from "@mui/icons-material";
import { FaUpload } from "react-icons/fa";
import { TerminalContext } from "../../terminal";

export function NewsAgentCommand({ lineIndex, containerIndex }: { lineIndex: number; containerIndex: number }) {

    const [searchQuery, setSearchQuery] = useState("");
    const terminalContext = useContext(TerminalContext);
    const [isReadOnly, setIsReadOnly] = useState(false);
    const AxiosAuthClient = useAxiosAuthClient();

    useEffect(()=>{
        if (terminalContext?.lines)
            {
                const linesLen = terminalContext?.lines.length - 1;
                if (lineIndex !== linesLen)
                    setIsReadOnly(true);
                else
                    setIsReadOnly(false);
            }
    },[terminalContext?.lines])

    useEffect(() => {
        if (searchQuery) {
            terminalContext?.updateContainerCommand(lineIndex, containerIndex, {
                type: "agent",
                description:"news",
                data: { searchQuery }
            });
        }
    }, [searchQuery, lineIndex, containerIndex]);

    const runQuery = async (event: React.FormEvent) => {
        event.preventDefault();

        const formData = new FormData();
        formData.append("search_query", searchQuery);

        try {
            const response = await AxiosAuthClient.post("/agents/newsAgent", formData, {
                headers: { "Content-Type": "multipart/form-data" },
            });
            console.log("Response: ", response.data);
        } catch (error) {
            console.error("Error: ", error);
        }
    };

    return (
        <form onSubmit={runQuery} className={styles.agent_command}>
            <div style={{ display: "flex", gap: "10px" }}>News</div>
            <div className={styles.agent_prompt} style={{ display: "flex", flexDirection: "column", alignItems: "center", boxSizing: "border-box" }}>
                <textarea
                    name="csvAgent_query"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    required
                    readOnly={isReadOnly}
                    placeholder={"query latest news for a topic"}
                />
            </div>
            <button type="submit">Run</button>
        </form>
    );
}