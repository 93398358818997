import React, {forwardRef} from "react";
import styles from './user_menu.module.css'
import { ChildCare } from "@mui/icons-material";
import BaseButton, {SaveButton, SlackLogin, LogOutButton, GoogleLogin, MicrosoftLogin, ChangePassButton} from "../buttons/buttons";
import { BsLightbulb, BsSun, BsMicrosoft } from "react-icons/bs";
import { Slack } from "lucide-react";

const UserMenu = forwardRef<HTMLDivElement>((props, ref) =>
    {
        return (
            <div className={styles.user_menu}>
                    <div className={styles.user_info}>
                        <span>Username</span>
                        <input type="text"/>
                        <span>Email</span>
                        <input type="text"/>
                        <ChangePassButton/>
                        <SaveButton></SaveButton>
                    </div>
                    <div className={styles.connect_services}>
                        <GoogleLogin/>
                        <MicrosoftLogin/>
                        <SlackLogin/>
                    </div>
                    <LogOutButton></LogOutButton>
            </div>
        )
    }
)

export default UserMenu;