import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
import Cookies from "js-cookie";
import envVariables from '../env';

interface AgentWebSocketContextI {
    AgentsPayload: any;
    loading: boolean;
    error: string | null;
    sendMessage: (message: string) => void;
}


const AgentsWebSocketContext = createContext<AgentWebSocketContextI | undefined>(undefined);

const AgentsWebSocketProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    
    const [AgentsPayload, setAgentsPayload] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const ws = useRef<WebSocket | null>(null);
    const protocol = window.location.protocol === 'https:' ? 'wss' : 'ws';

    const sendMessage = (message : string)=>{
        if (ws.current)
            ws.current.send(message)
    }

    useEffect(() => { 
        ws.current = new WebSocket(`${protocol}://${window.location.hostname}/api/v1/ws/agents`, );
        ws.current.onopen = () => {
            console.log('Agents WebSocket connected');
            sendMessage("reload")
        };

        ws.current.onmessage = (event) => {
            console.log('Message Received', event.data);
            const data = JSON.parse(event.data)
            setAgentsPayload(data)
            setLoading(false);
        };

        ws.current.onerror = (error) => {
            console.error('WebSocket error:', error);
            setError('WebSocket error occurred');
        };

        ws.current.onclose = () => {
            console.log('WebSocket disconnected');
            setLoading(false);
        };


        return () => {
            ws.current?.close();
        };
    }, []);

    return (
        <AgentsWebSocketContext.Provider value={{
            AgentsPayload,
            loading,
            error,
            sendMessage,
        }}>
            {children}
        </AgentsWebSocketContext.Provider>
    );
};

export default AgentsWebSocketProvider

export const useAgentsWebSocket = () => {
    return useContext(AgentsWebSocketContext);
};
