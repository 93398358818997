import React, { ChangeEvent, useRef } from 'react';
import './custom_file_input.module.css'; // Ensure this CSS file is created

interface CustomFileInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
    icon: React.ReactNode;
    onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

const CustomFileInput: React.FC<CustomFileInputProps> = ({ icon, onChange, ...props }) => {
    const fileInputRef = useRef<HTMLInputElement>(null); // Create a ref for the file input

    const handleButtonClick = () => {
        fileInputRef.current?.click();
    };

    return (
        <div className="custom-file-input">
            <input
                type="file"
                onChange={onChange}
                className="file-upload-input"
                ref={fileInputRef}
                style={{ display: 'none' }}
                {...props}
            />
             <button type="button" className="file-upload-button" onClick={handleButtonClick}>
                {icon}
            </button>
        </div>
    );
};

export default CustomFileInput;

