import React from "react";
import styles from './news_tool.module.css'

export default function NewsTool()
{
    return (
        <div className={styles.news_tool}>
            <span>News Extraction Tool</span>
            <form action="" className={styles.news_extraction_tool}>
                <label htmlFor="topic">topic</label>
                <textarea name="topic" id=""></textarea>
                <label htmlFor="from">from</label>
                <textarea name="from" id=""></textarea>
                <label htmlFor="to">to</label>
                <textarea name="to" id=""></textarea>
                <button type="submit">run</button>
            </form>
        </div>
    )
}