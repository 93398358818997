import React from "react";
import styles from './embedded.module.css'

interface EmbeddedMenu
{
    children : React.ReactNode;
}

export function EmbeddedMenu({children} : EmbeddedMenu)
{
    return (
        <div className={styles.embedded_menu}>
            {children}
        </div>
    )
}
