import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";

const useAxiosAuthClient = () => {
    const { getAccessTokenSilently, getIdTokenClaims } = useAuth0();

    const AxiosAuthClient = axios.create({
        baseURL: `/api/v1`,
    });
    
    useEffect(() => {
        const setupInterceptors = async () => {
            try {
                const token = await getAccessTokenSilently();
                axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
            } catch (error) {
                console.error("Error fetching access token:", error);
            }
        };

        const cleanup = setupInterceptors();

        if (cleanup && typeof cleanup === 'function') {
            return cleanup;
        }

    }, [getAccessTokenSilently]);

    return AxiosAuthClient;
};

export default useAxiosAuthClient;
