import React, {useEffect, useState, useContext} from "react";
import useAxiosAuthClient from "src/network/interceptors";
import styles from './agents_command.module.css'
import BaseButton from "../../buttons/buttons";
import { TerminalContext, TextArea } from "../terminal";
import { CsvAgentCommand } from "./agents/csv_agent";
import Spinner from "../../spinners/beatloader";
import { NewsAgentCommand } from "./agents/news_agent.module";

interface AgentsCommandI{
    lineIndex : number,
    containerIndex : number,
}

export default function AgentsCommand({lineIndex, containerIndex} : AgentsCommandI) {

    const [options, setOptions] = useState<string[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [selectedAgent, setSelectedAgent] = useState<string | null>(null);
    const [renderedComp, setRenderedComp] = useState<React.ReactNode | null>(null);
    const context = useContext(TerminalContext);
    const AxiosAuthClient = useAxiosAuthClient();

    const deployAgent = (agent: string) => {
        setSelectedAgent(agent);
        context?.appendInput()
    };

    useEffect(() => {
        switch (selectedAgent) {
            case "CSV":
                setRenderedComp(<CsvAgentCommand lineIndex={lineIndex} containerIndex={containerIndex} />);
                break;
            case "News":
                setRenderedComp(<NewsAgentCommand lineIndex={lineIndex} containerIndex={containerIndex}/>)
                break;
            case "linearRegression":
                setRenderedComp(<p>Linear Regression Compute</p>)
                break;

            default:
                setRenderedComp(null);
        }
    }, [selectedAgent]);

    useEffect(() => {
        const fetchOptions = async () => {
            try {
                setLoading(true);
                const response = await AxiosAuthClient.get(`/commands/@agents/options`);
                console.log(response.data)
                if (response.data.agents) {
                    setOptions(response.data.agents);
                } else {
                    setError('Unexpected response format');
                }
            } catch (err) {
                setError('Failed to fetch command options');
            } finally {
                setLoading(false);
            }
        };
        fetchOptions();
    }, []);

    if (loading) return <Spinner/>;
    if (error) return <p>{error}</p>;

    return (
        <div className={styles.agents_command}>
            { renderedComp ? (
                renderedComp
            ) : (
                options.map(agent => (
                    <BaseButton
                        children={null}
                        key={agent}
                        onClick={() => deployAgent(agent)}
                        Label={agent}
                    />
                ))
            )}
        </div>
    );
}
