import React, { useState } from 'react';
import styles from './change_pass.module.css'
import axios from 'axios';
import BaseButton from '../buttons/buttons';

const ChangePasswordForm = () => {

    const changePass = ()=>{
        console.log("change password")
    }

    return (
        <form action="post" className={styles.change_pass_form}>
            <label htmlFor="oldPassword">Old Password</label>
            <input type="text" name='oldPassword'/>
            <label htmlFor="NewPassword">New Password</label>
            <input type="text" name='NewPassword'/>
            <BaseButton onClick={changePass} children={null} Label={"Confirm"}></BaseButton>
        </form>
    )
};

export default ChangePasswordForm;
