import React, { useEffect, useState, useContext } from 'react';
import { TerminalContext } from '../terminal';
import BeatLoader from '../../spinners/beatloader';
import BaseButton from '../../buttons/buttons';
import useAxiosAuthClient from 'src/network/interceptors';
import styles from './tools_command.module.css'
import NewsTool from './tools/news_tool';

export default function ToolsCommand({ lineIndex, containerIndex }: { lineIndex: number, containerIndex: number }) {
    const [options, setOptions] = useState<string[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [selectedTool, setSelectedTool] = useState<string | null>(null);
    const [renderedComp, setRenderedComp] = useState<React.ReactNode | null>(null);
    const AxiosAuthClient = useAxiosAuthClient();

    const context = useContext(TerminalContext);

    const deployTool = (tool: string) => {
        setSelectedTool(tool);
        context?.appendInput();
    };

    useEffect(() => {
        switch (selectedTool) {
            case "news":
                setRenderedComp(<NewsTool/>)
                break;
            case "fundamentals":
                setRenderedComp(<p>Fundamentals Tool</p>);
                break;
            default:
                setRenderedComp(null);
        }
    }, [selectedTool]);

    useEffect(() => {
        const fetchOptions = async () => {
            try {
                setLoading(true);
                const response = await AxiosAuthClient.get(`/commands/@tools/options`);
                console.log(response.data.Tools)
                if (Array.isArray(response.data.tools)) {
                    setOptions(response.data.tools);
                } else {
                    setError('Unexpected response format');
                }
            } catch (err) {
                setError('Failed to fetch command options');
            } finally {
                setLoading(false);
            }
        };
        fetchOptions();
    }, []);

    if (loading) return <BeatLoader />;
    if (error) return <p>{error}</p>;

    return (
        <div className={styles.tools_command}>
            {renderedComp ? (
                renderedComp
            ) : (
                options.map(tool => (
                    <BaseButton
                        children={null}
                        key={tool}
                        onClick={() => deployTool(tool)}
                        Label={tool}
                    />
                ))
            )}
        </div>
    );
}
