import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import AuthOutlet from '@auth-kit/react-router/AuthOutlet'
import useSignIn from "react-auth-kit/hooks/useSignIn";
import createStore from 'react-auth-kit/createStore';
import ChatApp from "./pages/chat/chat_app";
import AuthPage from './pages/auth/authenticate';
import { useAuth0 } from "@auth0/auth0-react";

export default function App() {
    const { isAuthenticated, isLoading } = useAuth0();

    if (isLoading) {
        return <div>Loading...</div>;
    }

    return (
        <Router>
            <Routes>
                <Route path="/" element={isAuthenticated ? <ChatApp /> : <Navigate to="/auth" />} />
                <Route path="/app" element={isAuthenticated ? <ChatApp /> : <Navigate to="/auth" />} />
                <Route path="/auth" element={<AuthPage />} />
            </Routes>
        </Router>
    );
}