import styles from './buttons.module.css'
import React, { useState } from 'react'
import envVariables from 'src/env';
import { BsSun, BsList, BsFillPersonFill, BsLayoutSidebarReverse } from "react-icons/bs";
import { FaGoogle, FaMicrosoft, FaSlack } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import ChangePasswordForm from '../forms/change_pass';


interface BaseButtonElements
{
    className?: string;
    children : React.ReactNode | null;
    onClick: React.MouseEventHandler<HTMLButtonElement> | null;
    Label : string | null;
}

export default function BaseButton({ className, children, onClick, Label} : BaseButtonElements)
{
    if (children)
    {
        return (
            <button className={`${styles.base_button} ${className}`} onClick={onClick ? onClick : undefined}>
                {children}
            </button>
        )
    }
    else
    {
        return (
            <button className={`${styles.base_button} ${className}`} onClick={onClick ? onClick : undefined}>
                {Label}
            </button>
        )
    }
}

export function SaveButton()
{
    return (
        <BaseButton Label={null} onClick={null}>
            {"Save"}
        </BaseButton>
    )
}

export function ToggleLightModeButton({onClick} : { onClick: React.MouseEventHandler<HTMLButtonElement> | null })
{
    console.log("toggle mode")
    return(
        <BaseButton Label={null} onClick={onClick}><BsSun className={styles.button_icon}></BsSun></BaseButton>
    )
}

export function ToggleSideBarButton()
{
    return (
        <BaseButton Label={null} onClick={null} className="toggle_sidebar">
            <BsLayoutSidebarReverse className={styles.button_icon}/>
        </BaseButton>
    )
}


export function AccountButton({onClick} : { onClick: React.MouseEventHandler<HTMLButtonElement> | null })
{
    return (
        <BaseButton Label={null} onClick={onClick} className="">
            <BsFillPersonFill className={styles.button_icon}/>
        </BaseButton>
    )
}

export function SettingsButton()
{
    return (
        <BaseButton Label={null} onClick={null} className="">
            <BsList className={styles.button_icon}/>
        </BaseButton>
    )
}

export function ChangePassButton()
{
    const [changePass, setChangePass] = useState(false);

    return (
        (
            changePass === true ? <ChangePasswordForm/> : 
            <BaseButton onClick={()=>(setChangePass(changePass ? false : true))} children={null} Label={"Change Password"}/>
        )
        
    )
}

export function LogOutButton()
{
    const navigate = useNavigate();
    const { logout } = useAuth0();

    return (
        <BaseButton onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })} children={null} Label={"Logout"}></BaseButton>
    )
}

export function GoogleLogin()
{
    const handleLogin = () => {
        window.location.href = `/api/v1/auth/google`;
    };

    return (
        <BaseButton onClick={handleLogin} children={<FaGoogle className={styles.button_icon}/>} Label={"Logout"}></BaseButton>
    )
}

export function MicrosoftLogin()
{
    const handleLogin = () => {
        window.location.href = `/api/v1/auth/microsoft-login`;
    };

    return (
        <BaseButton onClick={handleLogin} children={<FaMicrosoft  className={styles.button_icon}/>} Label={"Logout"}></BaseButton>
    )
}

export function SlackLogin()
{
    const handleLogin = () => {
        window.location.href = `/api/v1/auth/slack-login`;
    };

    return (
        <BaseButton onClick={handleLogin} children={<FaSlack  className={styles.button_icon}/>} Label={"Logout"}></BaseButton>
    )
}