import React, { createRef, forwardRef, useContext } from "react";
import { Children, useEffect, useImperativeHandle, useState, useRef, createContext } from 'react';
import { PromptFileUploadList } from "../displays/uploaded_files";
import CommandOptions from "./commands/prompt_command_options.module";
import Markdown from "react-markdown";

import styles from './terminal.module.css';
import '../../styles/global.css';

import FileInput from "../forms/file_input";
import BeatLoader from "../spinners/beatloader";
import { AppContext } from "src/pages/chat/chat_app";

interface TerminalContextI {
	appendInput : ()=>void, 
	createDefaultInput : ()=>React.ReactNode,
	appendContainer : (type: string, children: React.ReactNode)=>void,
	resetCommand : boolean,
	setResetCommand : (resetCommand : boolean)=>void,
	addNewLine : ()=>void,
	clearInputContainers: (lineIndex: number)=>void,
	addNewContainer : (line: TerminalLineI, type: string, children: React.ReactNode)=>void,
	sendingQuery : boolean,
	setSendingQuery : (sendingQuery : boolean)=>void,
	updateQuery : (lineIndex: number, containerIndex: number, value: string) =>void,
	resetContainer: (lineIndex: number, containerIndex: number) =>void
	updateOutputContainer : (lineIndex: number,containerIndex: number,newChild: React.ReactNode)=>void,
	updateInputContainer : (lineIndex: number,containerIndex: number,newChild: React.ReactNode)=>void,
	updateContainerStatus : (lineIndex: number, containerIndex: number, newStatus: string) =>void,
	updateContainerCommand : (lineIndex: number, containerIndex: number, command: Record<string,any>) =>void,
	updateContainerType : (lineIndex: number, containerIndex: number, newType: string) => void,
	lines : TerminalLineI[],
	defaultLine : TerminalLineI,
	defaultInputContainer: InputContainerI,
	setLines :React.Dispatch<React.SetStateAction<TerminalLineI[]>>;
	showCommandOptions : string,
	setShowCommandOptions : (showCommandOptions : string) => void,
	uploadFiles : FileList | null;
	setUploadFiles: (files : FileList | null)=>void;
	uploadComplete : string;
	setUploadComplete: (uploadComplete:string)=>void;
	fileInputRef : React.RefObject<HTMLInputElement>;
	isDone : boolean,
	setIsDone : (isDone : boolean)=>void
}

export const TerminalContext = createContext<TerminalContextI | undefined>(undefined)

interface TerminalLineProps {
	length: number,
	children: React.ReactNode
}

export function TerminalLineSymbol({symbol, className} : {symbol : string, className: string})
{
	return (
		<span className={className}><b>{symbol}</b></span>
	)
}

interface TextAreaProps {
	autoFocus: boolean | undefined;
	onKeyDown: React.KeyboardEventHandler<HTMLTextAreaElement> | undefined;
	onInput : ((e: React.ChangeEvent<HTMLTextAreaElement>)=>void) | null;
	className: string | undefined;
	readOnly?: boolean;
	value : string;
}

export const TextArea = React.forwardRef<HTMLTextAreaElement, TextAreaProps>(
	({autoFocus, onKeyDown, onInput, className, readOnly }, ref) => {
		const context = useContext(TerminalContext);
		const textAreaRef = useRef<HTMLTextAreaElement>(null);

		function autoResizeTextarea(textarea: HTMLTextAreaElement | null) {
			if (textarea) {
				textarea.style.height = 'auto';
				textarea.style.height = textarea.scrollHeight + 'px';
			}
		}

		const resizeHandler = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
			autoResizeTextarea(event.currentTarget);
		};

		const handleOnInput = (event : React.ChangeEvent<HTMLTextAreaElement>)=>{
			if (onInput) onInput(event)
			resizeHandler(event);
		}

		return (
			<textarea
				className={className}
				ref={ref}
				rows={1}
				onInput={handleOnInput}
				onKeyDown={onKeyDown}
				readOnly={readOnly}
			/>

		);
	}
);

export function TerminalLine({children, length} : TerminalLineProps)
{
	return (
		<div className={styles.terminal_line}>
			{children}
		</div>
	);
}

interface TerminalInputContainerI {
	lineIndex : number,
	containerIndex : number,
	ref: React.RefObject<HTMLDivElement> | null;
	children: React.ReactNode;
}

export const TerminalInputContainer = React.forwardRef<HTMLDivElement, TerminalInputContainerI>(
    ({ lineIndex, containerIndex, children }, ref) => {
        const terminalContext = useContext(TerminalContext);
		const nlines = terminalContext?.lines?.length ? terminalContext.lines.length - 1 : 0;
		const containersLen = terminalContext?.lines?.[nlines]?.input?.containers?.length ? terminalContext.lines[nlines].input.containers.length - 1 : 0;
		let isCurrent = false;
		if (lineIndex === nlines && containerIndex === containersLen)
			isCurrent = true

        return (
			<div className={styles.terminal_input_container_wrapper}>
				{ isCurrent && <div className={styles.promptPoint}></div>}
				<div ref={ref} className={styles.terminal_input_container}>
					{children}
				</div>
			</div>
        );
    }
);

interface TerminalOutputContainerI {
	lineIndex : number,
	containerIndex : number,
	ref: React.RefObject<HTMLDivElement> | null;
	children: React.ReactNode;
}

export const TerminalOutputContainer = React.forwardRef<HTMLDivElement, TerminalOutputContainerI>(
    ({ lineIndex, containerIndex, children }, ref) => {
        const terminalContext = useContext(TerminalContext);

        return (
			<div className={styles.terminal_input_container_wrapper}>
				<div ref={ref} className={styles.terminal_input_container}>
					{children}
				</div>
			</div>
        );
    }
);


export interface TerminalLineI {
	input: {
		symbol: React.ReactNode;
		containers: {
			ref: React.RefObject<HTMLDivElement>;
			type : string;
			query : string;
			childRef : React.RefObject<HTMLTextAreaElement>;
			child: React.ReactNode;
			command : Record<string, string | number | boolean | File>,
			status : string | null;
			files: string[]
		}[];
	};
	output:{
		symbol: React.ReactNode;
		containers: {
			ref: React.RefObject<HTMLDivElement>;
			type : string,
			child: React.ReactNode | null;
		}[];
	} 
}

export interface InputContainerI {
    ref: React.RefObject<HTMLDivElement>;
    query: string;
    type: "text";
    childRef: React.RefObject<HTMLTextAreaElement>;
    child: JSX.Element;
    command: Record<string, any>;
    status: string | null;
    files: string[];
}


export default function Terminal()
{
	const appcontext = useContext(AppContext);
	const terminalRef = useRef<HTMLDivElement|null>(null);
	const userMenuRef = useRef<HTMLDivElement>(null);
	const fileInputRef = useRef<HTMLInputElement>(null);
	const lastContainerRef = useRef<HTMLDivElement>(null);
	const terminalInputRefs = useRef<{ [key: number]: any }>({0:React.createRef()});
	
	const [resetCommand, setResetCommand] = useState<boolean>(false);
	const [activePosition, setActivePosition] = useState<{ top: number, left: number } | null>(null);
	const [uploadFiles, setUploadFiles] = useState<FileList | null>(null);
	const [uploadComplete, setUploadComplete] = useState<string>("");
	const [sendingQuery, setSendingQuery] = useState<boolean>(false);
	const [isDone, setIsDone] = useState<boolean>(false);
	const [showCommandOptions, setShowCommandOptions] = useState<string>("");

	const defaultInputContainer : InputContainerI = {
		ref: createRef<HTMLDivElement>(),
		query : "",
		type : "text",
		childRef: createRef<HTMLTextAreaElement>(),
		child: <TextArea autoFocus={true} value={''} onKeyDown={undefined} onInput={null} className={styles.terminal_input} />,
		command:{},
		status : null,
		files: []
	}
	
	const defaultLine = {
		input: {
			symbol: <TerminalLineSymbol className={styles.terminal_prompt_in} symbol="·" />,
			containers: [
				defaultInputContainer
			]
		},
		output: {
			symbol: <TerminalLineSymbol className={styles.terminal_prompt_out} symbol=">" />,
			containers: [{ref:React.createRef<HTMLDivElement>(), type:"text", child:null}]
		}
	}

	const [lines, setLines] = useState<TerminalLineI[]>(
		[defaultLine],
	);

	const addNewLine = () => {	
		setLines((prevLines) => [
			...prevLines,
			defaultLine,
		]);
	};

	const resetContainer = (lineIndex: number, containerIndex: number) => {
		setLines((prevLines) => {
			const updatedLines = [...prevLines];
			const lineToUpdate = updatedLines[lineIndex];
			if (lineToUpdate && lineToUpdate.input.containers[containerIndex]) {
				const containerToReset = lineToUpdate.input.containers[containerIndex];
				containerToReset.ref = createRef<HTMLDivElement>();
				containerToReset.type = "text";
				containerToReset.query = "";
				containerToReset.childRef = createRef<HTMLTextAreaElement>();
				containerToReset.child = (
					<TextArea autoFocus={true} value={""} onKeyDown={undefined} onInput={null} className={styles.terminal_input} />
				);
				containerToReset.command = {};
				containerToReset.status = null;
				containerToReset.files = [];
			}
			return updatedLines;
		});
	};

	const clearInputContainers = (lineIndex: number) => {
		setLines((prevLines) => {
			const updatedLines = [...prevLines];
			const lineToUpdate = updatedLines[lineIndex];
			if (lineToUpdate) {
				lineToUpdate.input.containers = [defaultInputContainer];
			}
			return updatedLines;
		});
	};
	
	const appendContainer = (type: string, children: React.ReactNode, query: string = "") => {
		const newRef = React.createRef<HTMLDivElement>();
		const newContainer = { ref: newRef, query, type: type, childRef: createRef<HTMLTextAreaElement>(), child: children, command: {}, status : null, files: [] };
		
		setLines((prevLines) => {
			const lastLineIndex = prevLines.length - 1;
			if (lastLineIndex < 0) return prevLines;
			
			const lastLine = { ...prevLines[lastLineIndex] };  // Copia profunda de la última línea
			const updatedContainers = [
				...lastLine.input.containers,
				newContainer
			];
			
			const updatedLine = {
				...lastLine,
				input: {
					...lastLine.input,
					containers: updatedContainers,  // Copia profunda de los contenedores
				},
			};
			
			return [
				...prevLines.slice(0, lastLineIndex),
				updatedLine,  // Actualiza la referencia de la línea
				...prevLines.slice(lastLineIndex + 1),
			];
		});
	};
	
	const createDefaultInput = ()=>{
		return (
			<TextArea autoFocus={true} onKeyDown={undefined} onInput={null} value="" className={styles.terminal_input}/>
		)
	}

	const appendInput = ()=>{
		appendContainer("text", createDefaultInput())
	}

	const addNewContainer = (line: TerminalLineI, type: string, children: React.ReactNode) => {
		const newRef = React.createRef<HTMLDivElement>();
		const newContainer = { 
			ref: newRef,
			query : "",
			type: type, 
			childRef: createRef<HTMLTextAreaElement>(),
			child: children,
			command:{},
			status: null, 
			files:[]
		};


		setLines((prevLines) => {
			const lineIndex = prevLines.findIndex(l => l === line);
			if (lineIndex === -1) return prevLines;

			const updatedContainers = [
				...prevLines[lineIndex].input.containers,
				newContainer
			];

			const updatedLine = {
				...prevLines[lineIndex],
				input: {
					...prevLines[lineIndex].input,
					containers: updatedContainers,
				},
			};

			return [
				...prevLines.slice(0, lineIndex),
				updatedLine,
				...prevLines.slice(lineIndex + 1),
			];
		});
	};

	const updateContainerStatus = (lineIndex: number, containerIndex: number, newStatus: string) => {
		setLines((prevLines) => {
			const updatedLines = [...prevLines];
			if (updatedLines[lineIndex] && updatedLines[lineIndex].input.containers[containerIndex]) {
				const updatedLine = { ...updatedLines[lineIndex] };
				const updatedContainer = { ...updatedLine.input.containers[containerIndex] };
				updatedContainer.status = newStatus;
				updatedLine.input.containers[containerIndex] = updatedContainer;

				updatedLines[lineIndex] = updatedLine;
			}
			return updatedLines;
		});
	};

	const updateContainerType = (lineIndex: number, containerIndex: number, newType: string) => {
		setLines((prevLines) => {
			const updatedLines = [...prevLines];
			if (updatedLines[lineIndex] && updatedLines[lineIndex].input.containers[containerIndex]) {
				const updatedLine = { ...updatedLines[lineIndex] };
				const updatedContainer = { ...updatedLine.input.containers[containerIndex] };
				updatedContainer.type = newType;
				updatedLine.input.containers[containerIndex] = updatedContainer;

				updatedLines[lineIndex] = updatedLine;
			}
			return updatedLines;
		});
	};

	const updateContainerCommand = (lineIndex: number, containerIndex: number, command: Record<string,any>) => {
		setLines((prevLines) => {
			const updatedLines = [...prevLines];
			if (updatedLines[lineIndex] && updatedLines[lineIndex].input.containers[containerIndex]) {
				const updatedLine = { ...updatedLines[lineIndex] };
				const updatedContainer = { ...updatedLine.input.containers[containerIndex] };
				updatedContainer.command = command;
				updatedLine.input.containers[containerIndex] = updatedContainer;
				updatedLines[lineIndex] = updatedLine;
			}
			return updatedLines;
		});
	};

	const updateInputContainer = (
		lineIndex: number,
		containerIndex: number,
		newChild: React.ReactNode
	) => {
		setLines((prevLines) => {
			const updatedContainers = [...prevLines[lineIndex].input.containers];
			const containerToUpdate = updatedContainers[containerIndex];
			const updatedContainer = {
				...containerToUpdate,
				child: newChild,
			};
			updatedContainers[containerIndex] = updatedContainer;
			const updatedLine = {
				...prevLines[lineIndex],
				input: {
					...prevLines[lineIndex].input,
					containers: updatedContainers,
				},
			};
	
			return [
				...prevLines.slice(0, lineIndex),
				updatedLine,
				...prevLines.slice(lineIndex + 1),
			];
		});
	};

	const updateOutputContainer = (
		lineIndex: number,
		containerIndex: number,
		newChild: React.ReactNode
	) => {
		setLines((prevLines) => {
			const updatedContainers = [...prevLines[lineIndex].output.containers];
			const containerToUpdate = updatedContainers[containerIndex];
			const updatedContainer = {
				...containerToUpdate,
				child: newChild,
			};
			updatedContainers[containerIndex] = updatedContainer;
			const updatedLine = {
				...prevLines[lineIndex],
				output: {
					...prevLines[lineIndex].output,
					containers: updatedContainers,
				},
			};
	
			return [
				...prevLines.slice(0, lineIndex),
				updatedLine,
				...prevLines.slice(lineIndex + 1),
			];
		});
	};

	const updateQuery = (lineIndex: number, containerIndex: number, value: string) => {
        setLines(prevLines => {
            const updatedLines = [...prevLines];
            updatedLines[lineIndex].input.containers[containerIndex].query = value;
            return updatedLines;
        });
    };

    const handleInput = (lineIndex: number, containerIndex: number, value: string) => {
        updateQuery(lineIndex, containerIndex, value);
    };


	const prepareLinesForJSON = (lines: TerminalLineI[]) => {
		return lines.map(line => ({
			input: {
				symbol: typeof line.input.symbol === 'string' ? line.input.symbol : "·", 
				containers: line.input.containers.map(container => {
					if (container) {
						return {
							type: container.type,
							query: container.query,
							command: container.command,
							status: container.status,
							files: container.files,
						};
					}
					return {
						type: "text",
						query: "",
						command: "",
						status: "",
						files: [],
					};
				}),
			},
			output: {
				symbol: typeof line.output.symbol === 'string' ? line.output.symbol : ">",
				containers: line.output.containers.map(container => {
					if (container) {
						return {
							type: container.type,
							child: Array.isArray(container.child)
								? container.child.map(child => (typeof child === 'string' ? child : null))
								: [],
						};
					}
					return {
						type: "text",
						child: [],
					};
				}),
			},
		}));
	};

	const handleSendQuery = async (lineIndex: number, containerIndex: number) => {
		const input = lines[lineIndex].input.containers[containerIndex].query;

		if (input.trim() !== "") {
			try {
				let currentText = '';
				updateOutputContainer(lineIndex, containerIndex, <BeatLoader />);
				setSendingQuery(true);

				const sanitizedLines = prepareLinesForJSON(lines);
				const queryData = JSON.stringify({query:sanitizedLines});
	
				const response = await fetch("/api/v1/chat/sendQuery", {
					method: "POST",
					headers:{
						'Authorization':`Bearer ${appcontext?.accessToken}`,
						'Content-Type': 'application/json'
					},
					body: queryData,
				});
	
				if (!response.ok) {
					throw new Error("Error sending query");
				}
	
				const data = await response.json();
				console.log(data);
	
				if (data?.output) {
					currentText = data.output;
				} else {
					console.error("Response does not contain expected 'output'");
					currentText = "Error: No output in response";
				}

				const isHTML = (str: string) => /<\/?[a-z][\s\S]*>/i.test(str);
	
				setLines((prevLines) => {
					const updatedLines = [...prevLines];
					const updatedLine = { ...updatedLines[lineIndex] };
					const updatedOutputContainers = [...updatedLine.output.containers];
	
					updatedOutputContainers[containerIndex] = {
						...updatedOutputContainers[containerIndex],
						child: (
							isHTML(currentText) ?
							<div className={styles.terminal_output} dangerouslySetInnerHTML={{ __html: isHTML(currentText) ? currentText : '' }}
							></div> :
							<div className={styles.terminal_output}><Markdown>{currentText}</Markdown></div>
						),
					};
	
					updatedLine.output.containers = updatedOutputContainers;
					updatedLines[lineIndex] = updatedLine;
					return updatedLines;
				});
				setSendingQuery(false);
				addNewLine();
			} catch (error) {
				console.error("Error sending query:", error);
				setSendingQuery(false);
			}
		}
	};
	

	const handleTerminalChange = () => {
        if (terminalRef.current) {
            terminalRef.current.scrollTop = terminalRef.current.scrollHeight;
        }
    };

	useEffect(() => {
        if (terminalRef.current) {
            terminalRef.current.scrollTop = terminalRef.current.scrollHeight;
        }
    }, [lines]);

	return (
		<TerminalContext.Provider value={{
			appendInput,
			createDefaultInput,
			appendContainer,
			resetCommand,
			setResetCommand,
			addNewLine,
			sendingQuery,
			setSendingQuery,
			clearInputContainers,
			addNewContainer,
			resetContainer,
			updateOutputContainer,
			updateInputContainer,
			updateContainerStatus,
			updateContainerType,
			updateContainerCommand,
			updateQuery,
			lines,
			setLines,
			defaultLine,
			defaultInputContainer,
			showCommandOptions,
			setShowCommandOptions,
			uploadFiles,
			uploadComplete,
			setUploadComplete,
			fileInputRef,
			setUploadFiles,
			isDone,
			setIsDone,
		}}>
			<div onChange={()=>{}}ref={terminalRef} id={styles.terminal} className="panel">
				{lines.map((line, lineIndex) => (
					<React.Fragment key={`terminal-line-${lineIndex}`}>
						{/* Input Section */}
						<TerminalLine length={line.input.containers.length}>
							<div 
								className={styles.terminal_input_content}
							>
								{
									line.input.containers.map((container, containerIndex) => (
										<TerminalInputContainer
											lineIndex={lineIndex}
											containerIndex={containerIndex}
											ref={container.ref} 
											key={`input-container-${lineIndex}-${containerIndex}`}
										>
											{
												(() => {
													if (React.isValidElement(container.child) && container.child.type === TextArea) {
														const isLastLine = lineIndex === lines.length - 1;
														const isLastContainer = containerIndex === line.input.containers.length - 1;
														
														return React.cloneElement(container.child as React.ReactElement<TextAreaProps>, {
															autoFocus: isLastLine && isLastContainer,
															onKeyDown: (isLastLine && isLastContainer) ? 
																(e: React.KeyboardEvent<HTMLTextAreaElement>) => {
																	console.log("keypressing")
																	if (e.key === 'Enter')
																	{
																		e.preventDefault();
																		handleSendQuery(lineIndex, containerIndex);
																	}
																} 
																: undefined,
															onInput: (e: React.ChangeEvent<HTMLTextAreaElement>) => handleInput(lineIndex, containerIndex, e.currentTarget.value),
															readOnly: !(isLastContainer && isLastLine),
															value: container.query
														});
													}
													return container.child;
												})()
											}
											<CommandOptions lineIndex={lineIndex} containerIndex={containerIndex} key={`commandOptions-${lineIndex}-${containerIndex}`}/>
											{/* {
												line.input.containers[containerIndex].type === "agent" ? null :
												<div className={styles.terminal_line_controls}><button><FaPlay></FaPlay></button></div>
											} */}
										</TerminalInputContainer>
									))
								}
							</div>
						</TerminalLine>
						{line.output.containers.length > 0 && (
							<TerminalLine length={line.output.containers.length}>
								<div className={styles.terminal_output_content}>
									{line.output.containers.map((container, i) => (
										container && container.child ?
										<TerminalOutputContainer
											lineIndex={lineIndex}
											containerIndex={i}
											ref={container.ref}
											key={`output-container-${lineIndex}-${i}`}
										>
											{container.child}
										</TerminalOutputContainer> : null
									))}
								</div>
							</TerminalLine>
						)}
					</React.Fragment>
				))}
			</div>
			<FileInput 
				onChange={(event) => {
					const files = event.target.files;
					if (files && files.length > 0) {
						setUploadFiles(files);
					}
				}}
				ref={fileInputRef} 
			/>
		</TerminalContext.Provider>
	);
}
